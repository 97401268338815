<template>
  <div class="iq-card p-1 text-center">
    <welcome-page welcomeMessage="welcome To Target Page"></welcome-page>
  </div>
</template>
<script>
export default {
  name: 'welcomeSetting'
}
</script>
